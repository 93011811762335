import * as React from "react"
import { useEffect, useRef } from "react"
import classNames from "classnames"
import * as ReactDOM from "react-dom"
import { useMediaQuery } from "react-responsive"
import { Transition, TransitionStatus } from "react-transition-group"

import CustomScroll from "@components/CustomScroll"
import Icons from "@components/Icons"
import { Else, If, Then } from "@components/If"
import styled from "@emotion/styled"
import { scrollbarSize } from "@utils/scrollbar"

const defaultWidth = 500

const elModalRoot = process.browser && document.createElement("div")

if (elModalRoot) {
    elModalRoot.id = "modal-root"
    document.body.appendChild(elModalRoot)
}

export const modalOverlayClassName = "xmodal-overlay"
export const modalDialogClassName = "xmodal-dialog"

interface OverlayProps {
    state: TransitionStatus
    order: number
}

const Overlay = styled.div<OverlayProps>`
    background-color: transparent;

    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow-y: auto;
    z-index: ${({ order }) => order};

    @media screen and (min-width: 768px) {
        transition: opacity 0.3s;
        background-color: rgba(0, 0, 0, 0.5);

        opacity: ${({ state }) => {
            switch (state) {
                case "entering":
                    return 0
                case "entered":
                    return 1
                case "exiting":
                    return 0
                case "exited":
                    return 0
                default:
                    return 0
            }
        }};
    }
`

interface AnimationProps {
    state: TransitionStatus
}

const Animation = styled.div<AnimationProps>`
    height: 100%;

    transition: all 300ms;

    transition-property: opacity, margin-bottom;
    transition-duration: 0.3s;
    transition-timing-function: ease-in;

    opacity: ${({ state }) => {
        switch (state) {
            case "entering":
                return 0
            case "entered":
                return 1
            case "exiting":
                return 0
            case "exited":
                return 0
            default:
                return 0
        }
    }};

    margin-bottom: ${({ state }) => {
        switch (state) {
            case "entering":
                return "-300px"
            case "entered":
                return "0px"
            case "exiting":
                return "-300px"
            case "exited":
                return "-300px"
            default:
                return "-300px"
        }
    }};

    @media screen and (min-width: 768px) {
        transition: margin-top 300ms, opacity 300ms;

        transition-property: margin-top, opacity;
        transition-duration: 0.3s;
        transition-timing-function: ease;

        margin-bottom: 0;

        margin-top: ${({ state }) => {
            switch (state) {
                case "entering":
                    return "-200px"
                case "entered":
                    return "0px"
                case "exiting":
                    return "-200px"
                case "exited":
                    return "-200px"
                default:
                    return "-200px"
            }
        }};
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

const Wrapper = styled.div`
    padding: 75px 0px;
    min-height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding: 0px;
    }
`

interface ModalDialogProps {
    width: number
    minHeight?: number
    maxHeight?: number
}

const ModalDialog = styled.div<ModalDialogProps>`
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    width: ${({ width }) => `${width}px`};
    min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "auto")};

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    border-radius: 5px;
    overflow: hidden;

    transition: width 0.3s ease;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100vh;
        border-radius: 0px;
    }
`

const ModalHeader = styled.div`
    width: 100%;
    padding: 15px;

    display: flex;
    align-items: center;
`

const ModalTitle = styled.span`
    width: 100%;
    font-size: 18px;
    font-weight: 600;
`

const ModalClose = styled(Icons)`
    color: ${({ theme }) => theme.colors.semantic.secondary[400]};
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.colors.text.base};
    }
`

const ModalBody = styled.div`
    width: 100%;
    padding: 0 15px 15px 15px;
`

export interface ModalProps extends React.PropsWithChildren<{}> {
    /**
     * Whether the modal dialog is open or not
     */
    open: boolean
    /**
     * Specify a function that will be called when a user clicks overlay and close button on top right
     */
    onClose: () => void
    /**
     * Width of the modal dialog
     */
    width?: number
    /**
     * Min height of the modal dialog
     */
    minHeight?: number
    /**
     * Max height of the modal body
     */
    maxHeight?: number | string
    /**
     * Modal title
     */
    title: string
    /**
     * The z-index of the Modal
     */
    order?: number
    /**
     * Whether the modal header should be shown or custom modal header
     */
    header?: boolean | React.ReactNode
    /**
     * Custom dialog className
     */
    dialogClassName?: string
    /**
     * Custom body className
     */
    bodyClassName?: string
}

const Modal: React.FC<ModalProps> = ({
    open,
    onClose,
    width = defaultWidth,
    minHeight,
    maxHeight,
    title,
    children,
    order: orderProps,
    header = false,
    dialogClassName,
    bodyClassName
}: ModalProps) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 768px)"
    })

    const order = useRef(10 + (orderProps || 0))

    const handleEnter = () => {
        if (!elModalRoot) {
            return
        }

        order.current = order.current + elModalRoot.childElementCount - 1

        document.body.style.overflowY = "hidden"
        document.body.style.paddingRight = `${scrollbarSize()}px`
    }

    const handleExited = () => {
        if (!elModalRoot) {
            return
        }

        const lastModal = elModalRoot.childElementCount <= 1

        if (lastModal) {
            document.body.style.removeProperty("overflow-y")
            document.body.style.removeProperty("padding-right")
        }
    }

    const handleKeyPress = React.useCallback(
        (e: KeyboardEvent) => {
            if (open && e.key === "Escape") {
                onClose()
            }
        },
        [onClose, open]
    )

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress)

        return () => {
            document.removeEventListener("keydown", handleKeyPress)
        }
    }, [handleKeyPress])

    useEffect(() => {
        if (open) {
            handleEnter()
        }

        return () => {
            if (open) {
                handleExited()
            }
        }
    }, [open])

    if (!elModalRoot) {
        return null
    }

    return ReactDOM.createPortal(
        <Transition
            in={open}
            timeout={isDesktop ? 300 : 0}
            onEnter={handleEnter}
            onExited={handleExited}
            mountOnEnter
            unmountOnExit
        >
            {(state) => (
                <Overlay state={state} onClick={onClose} order={order.current} className={modalOverlayClassName}>
                    <Animation state={state}>
                        <Wrapper>
                            <ModalDialog
                                width={width}
                                minHeight={minHeight}
                                onClick={(e) => e.stopPropagation()}
                                className={classNames(modalDialogClassName, dialogClassName)}
                            >
                                <If condition={!header}>
                                    <Then>
                                        <ModalHeader>
                                            <ModalTitle>{title}</ModalTitle>
                                            <ModalClose icon='Times' onClick={onClose} />
                                        </ModalHeader>
                                    </Then>
                                    <Else>{header}</Else>
                                </If>

                                <If condition={maxHeight}>
                                    <Then>
                                        <CustomScroll
                                            autoHeight
                                            autoHeightMax={maxHeight}
                                            autoHide
                                            scrollId='modal-body-scroll'
                                        >
                                            <ModalBody className={bodyClassName}>{children}</ModalBody>
                                        </CustomScroll>
                                    </Then>
                                    <Else>
                                        <ModalBody className={bodyClassName}>{children}</ModalBody>
                                    </Else>
                                </If>
                            </ModalDialog>
                        </Wrapper>
                    </Animation>
                </Overlay>
            )}
        </Transition>,
        elModalRoot
    )
}

Modal.defaultProps = {
    width: defaultWidth,
    bodyClassName: undefined
}

export default Modal
