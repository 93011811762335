import React, { useImperativeHandle, useRef } from "react"
import { Scrollbars } from "react-custom-scrollbars"

import styled from "@emotion/styled"

const TrackVertical = styled.div`
    position: absolute;
    width: 6px;

    right: 0px;
    bottom: 0px;
    top: 0px;

    transition: opacity 0.3s ease;
`

const ThumbVertical = styled.div`
    background-color: #c4c4c4;
`

const TrackHorizontal = styled.div`
    position: absolute;
    height: 6px;

    left: 0px;
    bottom: 0px;
    right: 0px;

    transition: opacity 0.3s ease;
`

const ThumbHorizontal = styled.div`
    background-color: #c4c4c4;
`

export interface CustomScrollRef {
    scrollTop: (top: number) => void
}

export interface CustomScrollProps extends React.PropsWithChildren<{}> {
    /**
     * You can activate auto-height
     */
    autoHeight?: boolean
    /**
     * If you activate auto-height, you must set auto height min
     */
    autoHeightMin?: string | number
    /**
     * If you activate auto-height, you must set auto height min
     */
    autoHeightMax?: string | number
    /**
     * Enable auto-hide mode, when true tracks will hide automatically and are only visible while scrolling.
     */
    autoHide?: boolean
    /**
     * Set ID on body scroll element
     */
    scrollId?: string
    /**
     * You can set custom style
     */
    style?: React.CSSProperties
    /**
     * You can set view class
     */
    viewClassName?: string
}

const CustomScroll = React.forwardRef(
    (
        {
            children,
            autoHeight,
            autoHeightMin,
            autoHeightMax,
            autoHide,
            scrollId,
            style,
            viewClassName
        }: CustomScrollProps,
        ref
    ) => {
        const scrollbarsRef = useRef<Scrollbars>(null)

        useImperativeHandle(ref, () => ({
            scrollTop: (top: number) => {
                scrollbarsRef.current?.scrollTop(top)
            }
        }))

        const handleRenderTrackVertical = () => <TrackVertical />
        const handleRenderThumbVertical = () => <ThumbVertical />

        const handleRenderTrackHorizontal = () => <TrackHorizontal />
        const handleRenderThumbHorizontal = () => <ThumbHorizontal />

        // eslint-disable-next-line react/jsx-props-no-spreading
        const handleRenderView = (props: any) => <div {...props} id={scrollId} className={viewClassName} />

        return (
            <Scrollbars
                className='reku-scroll-bars'
                renderTrackVertical={handleRenderTrackVertical}
                renderThumbVertical={handleRenderThumbVertical}
                renderTrackHorizontal={handleRenderTrackHorizontal}
                renderThumbHorizontal={handleRenderThumbHorizontal}
                renderView={handleRenderView}
                autoHeight={autoHeight}
                autoHeightMin={autoHeightMin}
                autoHeightMax={autoHeightMax}
                autoHide={autoHide}
                ref={scrollbarsRef}
                style={style}
            >
                {children}
            </Scrollbars>
        )
    }
)

export default CustomScroll
