/* eslint-disable import/prefer-default-export */
export const getPageOffset = () => ({
    x:
        window.pageXOffset !== undefined
            ? window.pageXOffset
            : ((document.documentElement || document.body.parentNode || document.body) as HTMLElement).scrollLeft,
    y:
        window.pageYOffset !== undefined
            ? window.pageYOffset
            : ((document.documentElement || document.body.parentNode || document.body) as HTMLElement).scrollTop
})
